import React from 'react';
import styled, { css } from 'styled-components';
import InputRow from 'components/common/InputRow';
import RangeSlider from 'components/common/RangeSlider';
import RadioButton from 'components/common/RadioButton';
import config from 'config';
import { media } from 'styles/helpers';

const Slider = styled(RangeSlider)`
  ${media.tablet`
    width: 70%;
  `}
`;

const Styles = styled.div`
  padding: 2rem;
  .radios {
    > div {
      flex-basis: 50%;
      margin-right: 0;
    }
  }
  ${media.tablet`    
  .radios {
    > div {
      flex-basis: auto;
      margin-right: 3rem;
    }
  }
  `}
  ${p =>
    p.isPwaMode &&
    css`
      padding: 1rem 2rem;
      & div:first-child {
        margin-top: 0;
      }
    `}
`;

export default function PassphraseParams({ values, onChange, isPwaMode }) {
  const { modes } = config;
  const ppValues = values[modes.PP];

  const combinations = Math.pow(27, ppValues.length);

  return (
    <Styles isPwaMode={isPwaMode}>
      {!isPwaMode && (
        <p>
          Lange Passwörter sind leicht zu merken (und zu tippen!) 
          und sind oft genauso sicher wie eine zufällige Zeichenfolge, 
          besonders wenn sie länger sind als ein Passwort. 
          Dies ist eine gute Wahl für Dein "Master"-Passwort.
        </p>
      )}
      <InputRow>
        <Slider
          label="Wortzahl"
          name="length"
          value={ppValues.length}
          min={3}
          max={8}
          onChange={onChange}
          title="Wortzahl anpassen"
        />
      </InputRow>
      Begrenzungszeichen
      <InputRow className="radios">
        <RadioButton
          label="Bindestriche"
          name="delimiter"
          value="hyphen"
          checked={ppValues.delimiter === 'hyphen'}
          onChange={onChange}
          title="Trenne Wörter mit Bindestrichen"
        />
        <RadioButton
          label="Leerzeichen"
          name="delimiter"
          value="space"
          checked={ppValues.delimiter === 'space'}
          onChange={onChange}
          title="Trenne Wörter mit Leerzeichen"
        />
        <RadioButton
          label="Punkte"
          name="delimiter"
          value="period"
          checked={ppValues.delimiter === 'period'}
          onChange={onChange}
          title="Trenne Wörter mit Punkten"
        />
        <RadioButton
          label="123"
          name="delimiter"
          value="number"
          checked={ppValues.delimiter === 'number'}
          onChange={onChange}
          title="Trenne Wörter mit Zahlen"
        />
      </InputRow>
      Es gibt {combinations.toLocaleString('de-DE', {
    notation: 'compact',
    compactDisplay: 'short',
  })} Möglichkeiten, um dieses Passwort zu knacken.
    </Styles>
  );
}

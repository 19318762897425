import React from 'react';
import styled from 'styled-components';

const Styles = styled.div`
  margin: 3rem 0;
  ol {
    font-size: 0.9em;
  }
`;

export default function Instructions() {
  return (
    <Styles>
      <h2>Wie funktioniert es?</h2>
      <ol>
        <li>Setze Deine Parameter</li>
        <li>Wählen ein Passwort, jedes ist möglich</li>
        <li>Klicken zum kopieren</li>
        <li>Genieße den Tag!</li>
      </ol>
    </Styles>
  );
}

import React from 'react';
import styled from 'styled-components';

const Styles = styled.p`
  background: ${p => p.theme.colors.lightGray};
  background: white;
  padding: 2rem;
  font-size: 0.8em;
  border-radius: ${p => p.theme.borderRadius};
`;

export default function Disclaimer() {
  return (
    <Styles>
      Deine Passwörter werden auf diesem Gerät mit einem{' '}
      <a
        href="https://developer.mozilla.org/en-US/docs/Web/API/Crypto/getRandomValues"
        title="getRandomValues docs on Mozilla.org"
      >
        kryptographisch starken Zufallszahlengenerator
      </a>{' '}
      erstellt. Sie werden nicht übertragen oder in irgendeiner Weise gespeichert. 
      Achte einfach auf neugierige Blicke und schließe diesen Browser-Tab, wenn Du fertig bist.
    </Styles>
  );
}

import React from 'react';
import styled from 'styled-components';
// import config from 'config';
import { ReactComponent as Heart } from 'images/heart.svg';

const StyledContainer = styled.div`
  padding: 3rem 0;
  text-align: center;
  font-size: 0.7em;
  .v {
    font-family: ${p => p.theme.fontFixed};
  }
  svg {
    height: 1em;
    color: crimson;
    margin: 0 0 -0.25rem 0;
  }
`;

const LogoWrapper = styled.div`
  height: 100px;
  img {
    height: 100%;
  }
  margin-bottom: 50px;
`;

const Footer = () => {
  return (
    <StyledContainer>
      <LogoWrapper>
        <a href="https://3dt.digital" target="_blank" rel="noopener noreferrer">
          <img src="https://cms.3dt.digital/assets/5ec83385-8a78-496c-9895-4c6ae4348de0" alt="Logo 3DT"/>
        </a>
      </LogoWrapper>
      Gemacht mit {' '}
      <span role="img" aria-label="love">
        <Heart />
      </span>{' '}
      <br/>
      <a href="https://3dt.digital" target="_blank" rel="noopener noreferrer">Zur Website der 3DT</a>
      <br/>
      <a href="https://3dt.digital/impressum" target="_blank" rel="noopener noreferrer">Impressum</a> | <a href="https://3dt.digital/datenschutz" target="_blank" rel="noopener noreferrer">Datenschutz</a>   
    </StyledContainer>
  );
};

export default Footer;

import React from 'react';
import styled from 'styled-components';

const Styles = styled.div`
  margin: 3rem 0;
  p,
  ul {
    font-size: 0.9em;
  }
  ul {
    padding: 0 0 0 2.5rem;
  }
  li {
    margin: 1rem 0 1.25rem;
    list-style: none;
    position: relative;
    line-height: 1.5;
  }
  li:last-child {
    margin: 1rem 0 0;
  }
  li:before {
    content: '*';
    position: absolute;
    left: -2.25rem;
    top: 0;
    font-weight: bold;
    font-size: 1.25em;
    line-height: 1.2;
  }
`;

export default function About() {
  return (
    <Styles>
      <h2 id="about">Gute Passwort-Hygiene</h2>
      <p>
        Traue keiner {' '}
        <a
          href="https://www.tracesecurity.com/blog/articles/81-of-company-data-breaches-due-to-poor-passwords"
          title="81% of data breaches due to poor passwords"
          target="_blank"
            rel="noopener noreferrer"
        >
          Statistik
        </a>
        ! Sichere Passwörter sind Deine erste Verteidigung, wenn Du Dich gegen Bedrohungen im Internet schützen willst.
      </p>
      <ul>
        <li>Verwende keine persönlichen Informationen in Deinen Passwörtern, wie den Geburtstag Deines Hundes.</li>
        <li>
          Gib keine Anmeldedaten per E-Mail oder Textnachricht weiter. Diese sind völlig ungeschützt vor dem Abfangen durch Dritte.
        </li>
        <li>
        Verwende für jedes Konto ein anderes Passwort. Wenn ein Bösewicht die Meme-Generator-Website hackt, die Du vor 8 Monaten einmal benutzt hast, und Du dasselbe Passwort für alle Deine Konten wiederverwendest, bist Du möglicherweise aufgeschmissen. Und jetzt musst Du das Passwort für alle 308 Deiner anderen Konten ändern. (Das nervt.) Ja, ein eindeutiges Passwort für jedes Konto ist ohne einen Passwort-Manager so gut wie unmöglich...
        </li>
        <li>
          Verwende einen Passwort-Manager. Du hast{' '}
          <a
            href="https://www.google.com/search?q=passwort+manager"
            title="Google-Suche für 'passwort manager'"
            target="_blank"
            rel="noopener noreferrer"
          >
            viele Optionen
          </a>
          , Viele davon bieten eine kostenlose Version an. Hol' Dir eine mit einer mobilen App, 
          die zwischen all Deinen Geräten synchronisiert, sodass Du Deine Passwörter immer bei Dir hast. 
          Verwende ein langes, aber leicht zu merkendes "Master".{' '}
          <a href="https://xkcd.com/936/" title="XKCD-Comic über Passwortstärke" target="_blank"
            rel="noopener noreferrer">
            Kennwörter
          </a>{' '}
          wie <em>craig-accuracy-czech-shall-venice</em>
          , um Deinen Passwort-Tresor zu sichern, und lange Zufallspasswörter für einzelne Konten.
          Zum Glück haben Browser und Betriebssysteme mittlerweile fast alle eine grundlegende Passwortverwaltung eingebaut.
          Und mit dem Aufkommen biometrischer Zugangskontrollen (Face ID u.a.) gibt es wirklich keine Ausrede mehr, 
          nicht überall lange, komplexe Passwörter zu verwenden.
        </li>
        <li>
        Beantworte Sicherheitsfragen mit zufälligen Passwörtern oder Passphrasen und speichere 
        diese ebenfalls in Deinem Passwort-Manager. Einige dieser Informationen, wie z. B. der 
        Mädchenname Deiner Mutter, sind sicher schon im Umlauf. 
        Die meisten Manager enthalten inzwischen diese Funktionalität.
        </li>
        <li>
        Ändere Dein "Master"-Passwort regelmäßig. 
        Es dauert nur ein paar Minuten, um sich eine 5-Wort-Passphrase dauerhaft einzuprägen.
        </li>
      </ul>
    </Styles>
  );
}

import React from 'react';
import styled, { css } from 'styled-components';
import config from 'config';
import { media } from 'styles/helpers';
import InputRow from 'components/common/InputRow';
import CheckBox from 'components/common/CheckBox';
import RangeSlider from 'components/common/RangeSlider';

const Slider = styled(RangeSlider)`
  ${media.tablet`
    width: 70%;
  `}
`;

const Styles = styled.div`
  padding: 2rem;
  .checkboxes {
    > div {
      flex-basis: 50%;
      margin-right: 0;
    }
  }
  ${media.tablet`    
  .checkboxes {
    > div {
      flex-basis: auto;
      margin-right: 3rem;
    }
  }
  `}
  ${p =>
    p.isPwaMode &&
    css`
      padding: 1rem 2rem;
      & div:first-child {
        margin-top: 0;
      }
    `}
`;

const CombinationWrapper = styled.div`
overflow-wrap: break-word;
`;

export default function PasswordParams({ values, onChange, isPwaMode }) {
  const { modes } = config;
  const pwValues = values[modes.PW];

  const number = (pwValues.numbers === true ? 10 : null);
  const upper = (pwValues.upper === true ? 26 : null);
  const lower = (pwValues.lower === true ? 26 : null);
  const symbols = (pwValues.symbols === true ? 16 : null);
  const combinations = Math.pow((number + upper + lower + symbols), pwValues.length);

  return (
    <Styles isPwaMode={isPwaMode}>
      {!isPwaMode && (
        <p>
          Gute Kennwörter sind mindestens 12 Zeichen lang. Wenn möglich, 
          sollten sie Buchstaben, Zahlen und Symbole in zufälliger Reihenfolge enthalten. 
          Mach' Dein Passwort so lang, wie es dein Kontoanbieter zulässt. Du wirst es sowieso nicht mehr eingeben, 
          sobald Du einen{' '}
          <a href="#about" title="Gute Passwort-Hygiene">
            Passwort Manager verwendest.
          </a>
          .
        </p>
      )}
      <InputRow>
        <Slider
          label="Länge"
          name="length"
          value={pwValues.length}
          min={8}
          max={48}
          onChange={onChange}
          title="Passwort-Länge anpassen"
        />
      </InputRow>
      Zeichen
      <InputRow className="checkboxes">
        <CheckBox
          label="abc"
          name="lower"
          checked={pwValues.lower}
          onChange={onChange}
          title="Kleinbuchstaben einbeziehen"
        />
        <CheckBox
          label="ABC"
          name="upper"
          checked={pwValues.upper}
          onChange={onChange}
          title="Großbuchstaben einbeziehen"
        />
        <CheckBox
          label="123"
          name="numbers"
          checked={pwValues.numbers}
          onChange={onChange}
          title="Zahlen einbeziehen"
        />
        <CheckBox
          label="$@!"
          name="symbols"
          checked={pwValues.symbols}
          onChange={onChange}
          title="Symbole einbeziehen"
        />
      </InputRow>
      <CombinationWrapper>
      Es gibt {combinations.toLocaleString('de-DE', {
    notation: 'compact',
    compactDisplay: 'short',
  })} Möglichkeiten, um dieses Passwort zu knacken. {/*new Intl.NumberFormat('de-DE', { maximumSignificantDigits: 5 }).format(combinations)*/}
      </CombinationWrapper>
    </Styles>
  );
}
